<template>
    <form @submit.prevent="submitForm">
        <message v-if="ui.state === 'CREATED'" class="text-sm">Er is een account voor je aangemaakt. Bevestig je emailadres door op de link in de mail te klikken. De mail is verstuurd naar {{ ui.savedEmail }}</message>
        <message v-if="ui.state === 'ERROR'" message-type="error" class="text-sm">
            <template v-if="ui.errorMessage">
                <ul>
                    <li v-for="message in ui.errorMessage">
                        {{ message}}
                    </li>
                </ul>
            </template>
            <template v-if="!ui.errorMessage">We konden geen account aanmaken omdat dit emailadres al bestaat. </template>

        </message>
        <div class="grid grid-cols-1 sm:grid-cols-5 gap-4 text-left pt-4">
            <div class="col-span-1 sm:col-span-2">
                <label for="name" class="block mb-1 font-medium text-sm">
                    Jouw naam:
                </label>
                <input
                    type="text"
                    id="name"
                    v-model="name"
                    placeholder="Joost Geefgraag"
                    class="w-full rounded border border-brand-blue py-2 px-2  focus:outline-none focus:ring focus:border-brand-blue "
                />
            </div>
            <div class="col-span-1 sm:col-span-2">
                <label for="email" class="block mb-1 font-medium text-sm">
                    Vul je emailadres in:
                </label>
                <input
                    type="email"
                    id="email"
                    v-model="emailaddress"
                    placeholder="mijn@emailadres.nl"
                    required
                    class="w-full rounded border border-brand-blue py-2 px-2  focus:outline-none focus:ring focus:border-brand-blue "
                />
            </div>
            <div class="flex justify-end flex-col col-span-1">
                <button
                    type="submit"
                    class="bg-brand-teal rounded  py-2 w-full text-white  focus:outline-none focus:ring focus:border-blue-300"
                    :class="{'cursor-not-allowed':ui.state === 'SENDING'}"
                >
                    <span v-if="ui.state !== 'SENDING'"> aanmelden</span>
                    <span  v-if="ui.state === 'SENDING'" class="flex space-x-2 justify-center">

                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        laden
                    </span>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import Message from "./Message";
export default {
    name: "EntryForm",
    components: { Message },
    data: () => {
        return {
            name: "",
            emailaddress: "",
            ui: {
                state: 'DEFAULT',
                savedEmail: '',
                errorMessage: ''
            }
        };
    },
    methods: {
        submitForm() {
            if (this.ui.state === 'SENDING'){
                return
            }
            this.ui.state = 'SENDING';


            axios.post('/user', {
                    name: this.name,
                    email: this.emailaddress
                },
                {
                    validateStatus: function(status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    }
                }
            ).then((response) => {
                if (response.status === 200){
                    this.ui.state = 'CREATED';
                    this.ui.savedEmail = this.emailaddress;
                    this.resetFields();
                } else {
                    this.ui.state = 'ERROR';
                    this.ui.errorMessage = this.setErrors(response.data.errors);
                    // this.ui.errorMessage = response.data.errors;
                }
            }).catch((response) => {
                this.ui.state = 'ERROR';
            });

        },
        resetFields: function(){
            this.emailaddress = '';
            this.name = '';
        },

        setErrors(errors) {
            return errors.map((error) => {
                console.log(error);
            });
            // this.ui.errorMessage
            // return "";
        }
    }
};
</script>

<style scoped>

</style>
