<template>
    <div class="border-t border-gray-200  text-left">
        <button class="flex justify-between items-center w-full py-3 font-medium focus:outline-none focus:ring focus:border-blue-300 " @click="isOpen = !isOpen">
            <span>{{ title }}</span>
            <slot name="title"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 transform duration-200" :class="{'rotate-180 ': isOpen}">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            </slot>
        </button>

        <div class="text-brand-teal pb-3 text-base" v-show="isOpen">
            <slot></slot>

        </div>
    </div>
</template>

<script>
export default {
    name: "accordion",
    props: ['title'],
    data: () => {
        return {
            isOpen: false
        }
    }
}
</script>

<style scoped>

</style>
