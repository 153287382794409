require('./bootstrap');

import Vue from 'vue';

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


if (document.getElementById('app') !== null) {
    console.log('activeate!');
    const app = new Vue({
        el: '#app',
        data: function() {
            return {
                "navigationIsOpen": false
            }
        }
    });
}
