<template>
    <div class="max-w-2xl mx-auto text-white rounded px-6 py-2 shadow-md font-semibold text-left"
    :class="styles">
        <slot></slot>
    </div>

</template>

<script>
export default {
    name: "Message",
    props: {
        messageType: {
            type: String,
            default: 'success'
        }
    },
    computed: {
        styles: function(){
            if (this.messageType === 'error'){
                return 'bg-red-500';
            }
            if (this.messageType === 'warning'){
                return 'bg-brand-yellow';
                // return 'border border-red-500 text-red-500';
            }
            return 'bg-green-400';
        }
    }
};
</script>
